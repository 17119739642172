
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import Vue from "vue"
import { BimCompareExportRequestPayload } from "@evercam/shared/types/bim"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default Vue.extend({
  components: { CopyToClipboardBtn },
  props: {
    snapshotDate: {
      type: String,
      default: "",
    },
    bimDate: {
      type: String,
      default: "1970-01-01T00:00:00+00:00",
    },
    timezone: {
      type: String,
      default: "",
    },
    bimModel: {
      type: String,
      default: "4D",
    },
    bimLayerId: {
      type: Number,
      default: null,
    },
    maskLayerId: {
      type: Number,
      default: null,
    },
    transparency: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      dialog: false,
      processing: false,
      pending: false,
      created: false,
      gifUrl: "",
      mp4Url: "",
      mp4Loading: false,
      gifLoading: false,
      embedCode: "",
      title: "",
      stepper: 1,
      compareCreationError: false,
      endMessage: "",
      downloadTypes: {
        gif: "gif",
        mp4: "mp4",
      },
    }
  },
  computed: {
    ...mapStores(
      useMediaHubStore,
      useProjectStore,
      useLayoutStore,
      useCameraStore
    ),
    mediaHubLink() {
      return `${this.projectStore.projectRoute}/media-hub`
    },
    cameraName() {
      return this.projectStore.selectedProjectCameras.find(
        (camera) => camera.id === this.cameraExid
      ).name
    },
    cameraExid() {
      return this.cameraStore.selectedCameraExid
    },
    projectExid() {
      return this.projectStore.selectedProjectExid
    },
  },
  watch: {
    snapshotDate: function () {
      this.updateTitle()
    },
    bimDate: function () {
      this.updateTitle()
    },
    dialog(visibilityStatus) {
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareToggleExportDialog, {
        visible: visibilityStatus,
      })
    },
  },
  beforeDestroy() {
    this.clearTimer()
  },
  mounted() {
    this.$root.$on("toggle-compare-dialogue", () => {
      this.dialog = !this.dialog
    })
    this.updateTitle()
  },
  methods: {
    updateTitle() {
      let snapshotDate = this.$moment
          .tz(this.snapshotDate, this.timezone)
          .format("Do MMM YYYY"),
        bimDate =
          this.bimDate == "1970-01-01T00:00:00+00:00"
            ? "Final View"
            : this.$moment.tz(this.bimDate, this.timezone).format("Do MMM YYYY")
      this.title = `Evercam - ${this.cameraName} - BIM Compare (snapshot: ${snapshotDate} - BIM: ${bimDate})`
    },
    clearTimer() {
      this.processing = false
      this.created = false
      this.pending = false
    },
    closeDialog() {
      this.dialog = false
      this.compareCreationError = false
      this.stepper = 1
      this.clearTimer()
    },
    makeRandString() {
      let text = ""
      let possible = "abcdefghijklmnopqrstuvwxyz"
      let i = 0
      while (i < 6) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
        i++
      }

      return text
    },
    async downloadItem(type) {
      const url = this[`${type}Url`]
      this[`${type}Loading`] = true
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareDownloadAs, {
        fileType: type,
      })
      try {
        const response = await this.$axios.get(url, {
          params: {
            download: true,
          },
          responseType: "blob",
        })
        const extension = type
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(response, `${this.title}.${extension}`)
        }
        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(response)
        link.download = this.title + "." + extension
        link.click()
      } catch (error) {
        console.log(error)
      } finally {
        this[`${type}Loading`] = false
      }
    },
    async autoCheckCompareStatus(compareId, tries) {
      if (!this.dialog) {
        return
      }
      try {
        const compare = await EvercamApi.compares.cShow(
          this.projectExid,
          compareId
        )
        if (compare.status === "Completed") {
          this.created = true
          this.processing = false
          this.pending = false
          this.$notifications.success(this.$t("content.compare.compare_saved"))
        } else if (compare.status === "Processing" && tries < 42) {
          this.$setTimeout(
            () => this.autoCheckCompareStatus(compare.exid, tries + 1),
            5000
          )
        } else {
          this.pending = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createCompare() {
      this.processing = true
      let compareExid = `${this.title
        .replace(/[^A-Z0-9]/gi, "")
        .slice(0, 5)}-${this.makeRandString()}`.toLowerCase()
      let snapshotDate = this.$moment
        .tz(this.snapshotDate, this.timezone)
        .utc()
        .format("YYYY-MM-DD-HH_mm_ss")
      let bimDate = this.$moment
        .tz(this.bimDate, this.timezone)
        .utc()
        .format("YYYY-MM-DD-HH_mm_ss")
      // eslint-disable-next-line no-useless-escape
      this.embedCode = `<div id='evercam-compare'></div><script src='${window.location.origin}/evercamCompare.js' class='${this.cameraExid} ${snapshotDate} ${bimDate} ${compareExid} autoplay'><\/script>`
      let params: BimCompareExportRequestPayload = {
        name: this.title,
        snapshotDate: this.$moment
          .tz(this.snapshotDate, this.timezone)
          .toISOString(true),
        bimDate: this.$moment.tz(this.bimDate, "etc/utc").toISOString(true),
        cameraExid: this.cameraExid,
        embed: this.embedCode,
        compareExid: compareExid,
        createAnimation: true,
        bimTransparency: this.transparency,
        model: this.bimModel,
        layerId: this.bimLayerId,
        maskId: this.maskLayerId,
        evercamWatermark: this.layoutStore.isEvercamWatermark,
      }
      try {
        const compare = await EvercamApi.bim.exportCompare(
          this.projectExid,
          params
        )
        this.mediaHubStore.forceReloadMediaHub()
        this.$analytics.saveEvent(AnalyticsEvent.BimCompareSaveToMediaHub)
        this.endMessage = this.$t("content.bim.export_dialog.bim_in_progress")
        this.stepper++
        this.gifUrl = `${this.$config.public.apiURL}/projects/${this.projectExid}/media-hub/${compare.exid}.gif`
        this.mp4Url = `${this.$config.public.apiURL}/projects/${this.projectExid}/media-hub/${compare.exid}.mp4`
        this.$setTimeout(
          () => this.autoCheckCompareStatus(compare.exid, 1),
          10000
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.bim.export_dialog.bim_error"),
          error,
        })
        this.compareCreationError = true
      }
    },
    onUrlCopy(type) {
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareCopyDownloadFileUrl, {
        fileType: type,
      })
    },
    goToMediaHub() {
      this.$analytics.saveEvent(AnalyticsEvent.BimCompareGoToMediaHub)
    },
  },
})
